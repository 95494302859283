import React from 'react';
import { BackTop } from 'antd';
import { Footer, CookiePolicy } from '@btccomfe/btccomfe-base-ui';
import { Helmet } from 'react-helmet';
import './index.less';
import { ReactComponent as IconGotoTop } from '../../assets/images/icon-goto-top.svg';

const explorerBaseUrl = process.env.REACT_APP_EXPLORER_URL

const Layout = ({
    children
  }) => {
    return (
      <>
        <Helmet>
          <meta
            name="keywords"
            content='navigation,pow,pos,poc,DeFi,NFT,media,audit,bitcoin,pool,mine,mining,btc.top,slushpool,btc,eth,etc,ltc,dcr,f2pool,antpool,slush,ghash,antminer,bw,pool,via,viabtc,区块链导航,區塊鏈導航,Blockchain Navigation,Блокчейн-навигация,BTC.com,btc.com,btccom,explorer'
          />
          <meta
            name="description"
            content='BTC.com blockchain navigation provides an easy way to search blockchain programs or products.'
          />
        </Helmet>
        
        <div className="wrapper">
          {children}
        </div>

        <BackTop visibilityHeight={400} className="goto-top">
          <IconGotoTop />
        </BackTop>

        <Footer
          lang="en"
          explorerBaseUrl={explorerBaseUrl}
        />
        <CookiePolicy
          lang="en"
          style={{ position: 'fixed', zIndex: 999 }}
        />
      </>
    );
}

export default Layout;
