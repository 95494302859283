const routes = [
  {
    path: '/',
    name: 'Home',
    end: true,
    element: () => import('./HomePage'),
  },
  {
    path: '/apply',
    name: 'Apply',
    end: true,
    element: () => import('./Apply'),
  },
  {
    path: '/search',
    name: 'Search',
    end: true,
    element: () => import('./Search'),
  }
];

export default routes;
