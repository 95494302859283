import React from 'react';
import './index.less';
import notFoundImage from '../../assets/images/notfound.png';


const NotFound = ()=>{
  return (
    <div className='notFound warning-page'>
      <img src={notFoundImage} alt="Not Found" className="img"/>
      <p>
        Not Found
      </p>
    </div>
  );
}

export default NotFound
