import React from 'react';
import styles from './index.less';
import defaultImg from '../../assets/images/nodata.png';
import classnames from 'classnames';
const NoData = ({ placeholder, height = '270px', imgUrl, className }) => {
  const cls = classnames(styles.tableEmptyPlaceholder, className);
  return (
    <div
      className={cls}
      style={{
        height,
        background: `url(${imgUrl ? imgUrl : defaultImg}) no-repeat 50% 50%`,
        backgroundSize: `auto 100%`,
      }}
    >
      <div className={styles.tableEmptyText}>
        {placeholder}
      </div>
    </div>
  );
};

export default NoData;
