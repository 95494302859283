import React, { Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import routes from "./pages";
import Layout from "./components/Layout";
import NotFound from "./pages/Warning/NotFound";
import NoData from "./components/NoData";
import MetaTags from "react-meta-tags";
import { ConfigProvider } from "antd";
import "./assets/styles/App.less";

import "./assets/styles/header.scss";

import enUS from "antd/lib/locale/en_US";

// import zhCN from 'antd/lib/locale/zh_CN';
// import moment from 'moment';
// import 'moment/locale/zh-cn';
// moment.locale('en');
// moment.locale('zh-cn');

function App() {
  
  return (
    <Suspense fallback="">
      <ConfigProvider locale={enUS} renderEmpty={NoData}>
        <Layout>
          <MetaTags>
            {/* <!-- HTML Meta Tags --> */}
            <meta
              name="description"
              content="BTC.com blockchain navigation provides an easy way to search blockchain programs or products."
            />
            {/* <!-- Google / Search Engine Tags --> */}
            <meta
              itemProp="name"
              content="BTC.com is the first website for blockchainers"
            />
            <meta itemProp="image" content="" />
            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content="https://nav.btc.com" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="BTC.com is the first website for blockchainers"
            />
            <meta
              property="og:description"
              content="BTC.com blockchain navigation provides an easy way to search blockchain programs or products."
            />
            <meta property="og:image" content="" />
            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="BTC.com is the first website for blockchainers"
            />
            <meta
              name="twitter:description"
              content="BTC.com blockchain navigation provides an easy way to search blockchain programs or products."
            />
            <meta name="twitter:image" content="" />
          </MetaTags>
          <BrowserRouter>
            <Routes>
              {routes.map((route) => {
                const { element, ...rest } = route;
                route = {
                  ...rest,
                };
                // console.log(element)
                const path = route.path;
                const end = route.end

                let Component = React.lazy(element);

                return (
                  <Route key={Math.random()} end={end} path={path} element={<Component />} />
                );
              })}
              <Route key="404" path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Layout>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
